import { apiGetLocation } from '@/api/thirdApi';
import { apiGetAppInfo } from '@/api/publicApi';
import store from '@/store';
import storage from '@/storage';
apiGetLocation().then(res => {
  if (res) {
    store.commit('setCity', res.data.city);
  }
});
apiGetAppInfo().then(res => {
  if (res) {
    storage.set('appInfo', res.data);
  }
});
export default {
  name: 'App'
};