import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import storage from '@/storage'
// 创建路由规则
const routes = [{
  name: '/',
  component: () => import('@/views/Index')
}, {
  name: 'login',
  path: '/login',
  component: () => import('@/views/Login')
}, {
  name: 'order',
  path: '/order',
  component: () => import('@/views/Order')
}, {
  name: 'order-details-travel',
  path: '/order-details-travel',
  component: () => import('@/views/OrderDetailsTravel')
}, {
  name: 'travel',
  path: '/travel',
  component: () => import('@/views/Travel')
}, {
  name: 'travel-details',
  path: '/travel-details',
  component: () => import('@/views/TravelDetails')
}, {
  name: 'travel-order',
  path: '/travel-order',
  component: () => import('@/views/TravelOrder')
}, {
  name: 'plane',
  path: '/plane',
  component: () => import('@/views/Plane')
}]
// 创建路由实例
const router = createRouter({
  history: createWebHashHistory(),
  mode: 'history',
  routes
})

if (storage.get('token')) {
  store.commit('setToken', storage.get('token'))
}
// 路由拦截
router.beforeEach((to, from, next) => {
  // 判断是否是登录保护路由
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 验证浏览器本地存储是否有token（或从vuex、Cookie、localStorage中获取，依据自己的存储），用于判断是否登录
    if (store.state.token) {
      next() // 有token,进行request请求，后台还会验证token
    } else {
      next() // 有token,进行request请求，后台还会验证token
      // next({
      //   path: '/login'
      //   // 将刚刚要去的路由path（却无权限）作为参数，方便登录成功后直接跳转到该路由，这要进一步在登陆页面判断
      //   // query: { redirect: to.fullPath }
      // })
    }
  } else {
    next()
  }
})

// 创建登录组件
export default router
// 指定出口
