import httpRequest from '@/request'

// 腾讯地图获取位置
export function apiGetLocation () {
  return httpRequest({
    url: '/web/scenicspot/getaddress',
    method: 'post'
  })
}

// 微信分享
export function apiGetWxsign (data) {
  return httpRequest({
    url: '/web/service/wxsign',
    method: 'post',
    params: data
  })
}
