// 导入axios实例
import httpRequest from '@/request/index'
import store from '@/store'

// 获取验证码
export function apiGetInvite (data) {
  store.commit('setToastState', true)
  return httpRequest({
    url: '/web/auth/auth_code',
    method: 'post',
    params: data
  })
}

// 图片上传
export function apiPostFileUpload (data, header) {
  store.commit('setToastState', true)
  return httpRequest({
    url: '/web/file/upload',
    method: 'post',
    headers: header,
    data
  })
}

// OCR获取图片信息
export function apiGetUrlInfo (data) {
  store.commit('setToastState', true)
  return httpRequest({
    url: '/web/info/geturlinfo',
    method: 'post',
    params: data
  })
}

// 发帖保存
export function apiPostContentArticle (data) {
  return httpRequest({
    url: '/web/travelnotes/save',
    method: 'post',
    data
  })
}

// 获取文章内容
export function apiGetContentArticle (data) {
  return httpRequest({
    url: '/web/travelnotes/gettravelnotes',
    method: 'post',
    params: data
  })
}

// 获取路线内容
export function apiGetContentRoute (data) {
  return httpRequest({
    url: '/web/travelline/gettravelline',
    method: 'post',
    params: data
  })
}

// 获取景区内容
export function apiGetContentPlace (data) {
  return httpRequest({
    url: '/web/scenicspot/getscenicspot',
    method: 'post',
    params: data
  })
}

// 点赞喜欢
export function apiPostDoType (data) {
  return httpRequest({
    url: '/web/travelnotes/tolike',
    method: 'post',
    params: data
  })
}

// 游记评论
export function apiPostTocomment (data) {
  return httpRequest({
    url: '/web/travelnotes/tocomment',
    method: 'post',
    params: data
  })
}

// 回复列表
export function apiGetReplylist (data) {
  return httpRequest({
    url: '/web/travelnotes/getreplylist',
    method: 'post',
    params: data
  })
}

// 提交订单
export function apiPostPlaceorder (data) {
  return httpRequest({
    url: '/web/travelline/placeorder',
    method: 'post',
    data
  })
}

// 获取所有城市
export function apiGetCityAll (data) {
  return httpRequest({
    url: '/web/scenicspot/allcity',
    method: 'post',
    data
  })
}

// 获取某一个城市
export function apiGetCity (data) {
  return httpRequest({
    url: '/web/scenicspot/getcity',
    method: 'post',
    params: data
  })
}

// 获取APP信息
export function apiGetAppInfo (data) {
  return httpRequest({
    url: '/web/app/info',
    method: 'post'
  })
}

// 获取用户信息
export function apiGetOthermemberinfo (data) {
  return httpRequest({
    url: '/web/info/getothermemberinfo',
    method: 'post',
    params: data
  })
}
// 关注用户
export function apiGetTofollow (data) {
  return httpRequest({
    url: '/web/info/tofollow',
    method: 'post',
    params: data
  })
}
// 取消关注
export function apiPostDelfollow (data) {
  return httpRequest({
    url: '/web/info/delfollow',
    method: 'post',
    params: data
  })
}
